@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
  }

  @font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
  }

  @font-face {
    font-family: "poppins";
    src: url("/fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
  }

  @font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
  }

  @font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  #__next {
    @apply p-0 m-0 w-full h-full font-poppins;
  }

  * {
    box-sizing: border-box;
    -webkit-appearance: none;
  }

  input,
  button,
  a,
  textarea {
    outline: none;
  }

  .bg-hash-red-gradient {
    /* @apply bg-gradient-to-r from-vivid-tangerine to-coyote-brown; */
    @apply bg-gradient-to-r from-terra-cotta to-hash-red;
  }

  .bg-hash-red-gradient-alt {
    @apply bg-gradient-to-r from-vivid-tangerine to-coyote-brown;
  }

  .text-hash-red-gradient {
    @apply bg-clip-text text-transparent bg-hash-red-gradient;
  }

  .bg-auth-loading {
    background: linear-gradient(
      90deg,
      rgba(102, 102, 102, 0.86) 2.01%,
      #1e2128 97.7%
    );
  }
  .bg-signup-auth-loading {
    background: linear-gradient(90.08deg, #f1f1f1 2.01%, #dddddd 87.7%);
  }

  .bg-messages-loading {
    background: linear-gradient(
      90deg,
      rgb(224 221 221 / 86%) 80.01%,
      #dadada 100%
    );
  }

  .bg-full {
    background-size: 100% 100%;
  }

  * {
    scrollbar-width: auto;
    scrollbar-color: #979eb5 #ffffff;
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: #faf9f8;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #979eb5;
    border-radius: 0px;
    border: 3px solid #fbfbfb;
  }

  .scrollbar-gutter-stable {
    scrollbar-gutter: stable;
  }

  .hashmail-text-underline {
    @apply relative after:absolute after:-bottom-1 after:left-0 after:w-full after:h-0.5;
  }
}

.ql-editor {
  @apply font-poppins;
}

.message-preview {
  @apply text-xs.5;
}

.message-preview a {
  @apply underline text-blue-700;
}

.message-preview href {
  @apply underline text-blue-700;
}

.message-preview ul,
menu,
dir {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.message-preview ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.message-preview li {
  display: list-item;
  margin-top: 0.5em;
  text-align: -webkit-match-parent;
}
.message-preview ul ul,
ol ul {
  list-style-type: circle;
}
.message-preview ol ol ul,
ol ul ul,
ul ol ul,
ul ul ul {
  list-style-type: square;
}
.message-preview dd {
  display: block;
  margin-inline-start: 40px;
}
.message-preview dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0em;
  margin-inline-end: 0em;
}
.message-preview dt {
  display: block;
}
.message-preview ol ul,
ul ol,
ul ul,
ol ol {
  margin-block-start: 0;
  margin-block-end: 0;
}

.no-scroll-bar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
